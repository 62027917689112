<template>
  <div class="about">
    <h1 class="titre">Ressources pour les jeux solo</h1>
    <h2 class="titre">En français</h2>
    <h3 class="preza">Sites / blogs</h3>
    <b-list-group>
      <b-list-group-item href="https://le-chat-solitaire.com/" target="_blank" class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">La Caverne du Chat Solitaire</h5>
        </div>
        <p class="mb-1">
          Le blog de Skinner, le chat solitaire. Blog dédié aux jeux de plateau en solo, un confrère en somme.
        </p>
      </b-list-group-item>
      <b-list-group-item href="https://comboteurfou.wordpress.com/" target="_blank" class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">Le Comboteur Fou</h5>
        </div>
        <p class="mb-1">
          Blog créé en 2015. Pas mal de retours sur des jeux solo
        </p>
      </b-list-group-item>
      <b-list-group-item href="https://www.sologames-sylweb.com/" target="_blank" class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">Solo Games</h5>
        </div>
        <p class="mb-1">
          Le site des ressources solo avec des aides de jeux, des variantes, des challenges. Une référence.
        </p>
      </b-list-group-item>
      <b-list-group-item href="http://tribulations-joueur-solo.fr/" target="_blank" class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">Les Tribulations d'un Joueur Solitaire</h5>
        </div>
        <p class="mb-1">
          Un blog essentiellement consacré à Horreur à Arkham jce.
        </p>
      </b-list-group-item>
      <b-list-group-item href="https://www.facebook.com/groups/512020992503770/about" target="_blank" class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">Groupe Facebook Jeux de Société en Solo</h5>
        </div>
        <p class="mb-1">
          Communauté francophone de joueurs solo.
        </p>
      </b-list-group-item>
      <b-list-group-item href="https://sdajce.forumactif.org/" target="_blank" class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">Forum SdA jce</h5>
        </div>
        <p class="mb-1">
          Le forum de référence pour les joueurs du Seigneurs des Anneaux jce.
        </p>
      </b-list-group-item>
    </b-list-group>
    <h3 class="preza">Chaines Youtube</h3>
    <b-list-group>
      <b-list-group-item href="https://www.youtube.com/channel/UCCVnweug7ibZkTVJcdfjpFA" target="_blank" class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">Board Game Québec</h5>
        </div>
        <p class="mb-1">
          Board Game Québec est une chaîne YouTube entièrement québécoise sur l’univers des jeux de société, avec pas mal de parties solo.
        </p>
      </b-list-group-item>
      <b-list-group-item href="https://www.youtube.com/channel/UCDhasU2CHmYLayW-zDHursw" target="_blank" class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">Des Jeux en Solo</h5>
        </div>
        <p class="mb-1">
          Chaîne d'Iruka dédié aux jeux de société jouables en solo.
        </p>
      </b-list-group-item>
      <b-list-group-item href="https://www.youtube.com/channel/UCWpJSab2HUs7AjFurpyViVA" target="_blank" class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">Etrigane</h5>
        </div>
        <p class="mb-1">
          Chaine qui présente des jeux de société et des jeux vidéo. Sur la partie jeux de société, beaucoup de jeux solo sont présentés (explications de règles et parties).
        </p>
      </b-list-group-item>
      <b-list-group-item href="https://www.youtube.com/c/Zonejeuxdesoci%C3%A9t%C3%A9/featured" target="_blank" class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">Zone Jeux de Société</h5>
        </div>
        <p class="mb-1">
          Chaine québécoise présentant des jeux de société (règles et parties) avec pas mal de jeux solo.
        </p>
      </b-list-group-item>
    </b-list-group>
    <br/>
    <h2 class="titre">En anglais</h2>
    <h3 class="preza">Sites / blogs</h3>
    <b-list-group>
      <b-list-group-item href="http://www.beyondsolitaire.net/" target="_blank" class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">Beyond Solitaire</h5>
        </div>
        <p class="mb-1">
          Site dédié aux jeux solo.
        </p>
      </b-list-group-item>
      <b-list-group-item href="https://thesolomeeple.com/blog/" target="_blank" class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">The Solo Meeple</h5>
        </div>
        <p class="mb-1">
          News, reviews de jeux solo.
        </p>
      </b-list-group-item>
    </b-list-group>
    <h3 class="preza">Chaines Youtube</h3>
    <b-list-group>
      <b-list-group-item href="https://www.youtube.com/channel/UCUbSYmhAlOW1RorV_jXmo2w" target="_blank" class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">Beyond Solitaire</h5>
        </div>
        <p class="mb-1">
          La chaine du blog du même nom.
        </p>
      </b-list-group-item>
      <b-list-group-item href="https://www.youtube.com/c/OneStopCoopShop/featured" target="_blank" class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">One Stop Coop</h5>
        </div>
        <p class="mb-1">
          Review et parties de jeux solo.
        </p>
      </b-list-group-item>
      <b-list-group-item href="https://www.youtube.com/channel/UCdyvGEgHQNI8CEcAxbeLPIQ" target="_blank" class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">Box of Delights</h5>
        </div>
        <p class="mb-1">
          La chaine de Ricky Royal.
        </p>
      </b-list-group-item>
      <b-list-group-item href="https://www.youtube.com/c/RollingSolo/featured" target="_blank" class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">Rolling Solo</h5>
        </div>
        <p class="mb-1">
          Chaine dédié aux solo.
        </p>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>

export default {
  mounted: function () {
    document.title = 'A propos'
    document.querySelector('meta[name="description"]').setAttribute('content', 'Ressources pour le joueur solitaire')
  },
  methods: {
  }
}
</script>

<style scoped>
.about {
  margin: 5% 10% 5% 10%;
  text-align: left;
}

.but {
  margin-top: 10px;
}

.preza {
  margin-top: 10px;
}

.social {
  margin-top: 10px;
}

.titre {
  text-align: center;
}

.technique {
  margin-top: 10px;
}
</style>
